import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Products = () => (
  <Layout>
    <SEO title="Products" />
    <h1>Coming Soon!</h1>
    <p>This is the page that will house the products</p>
  </Layout>
)

export default Products;
